@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

html {
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}
